import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import {
    Asignatura,
    Asignaturas,
    Clasificacion,
    Clasificaciones,
    ClasificacionTipo,
    ClasificacionTipos
} from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    //
    selector: "embed-clasificaciones",
    templateUrl: "embed_clasificaciones.component.html",
    styleUrls: ["embed_clasificaciones.component.scss"]
})
export class EmbedClasificacionesComponent implements OnInit, OnChanges {
    @Input() recursoEducativo: string
    @Input() asignaturaId: number
    @Input() perfil: string = "admin"

    aes: Clasificacion[] = []
    alumno: Clasificacion = undefined
    aptoParaPlanPersonal: Clasificacion[] = []
    asignaturas: Asignatura[]
    clasificacionId: number
    cmoOptions: Clasificacion[] = []
    competencias: Clasificacion[] = []
    afirmaciones: Clasificacion[] = []
    evidencias: Clasificacion[] = []
    componentes: Clasificacion[] = []
    bloques_mx: Clasificacion[] = []
    temas_mx: Clasificacion[] = []
    subtemas_mx: Clasificacion[] = []
    componentes_mx: Clasificacion[] = []
    contenidocentrales_mx: Clasificacion[] = []
    categoria_organizadoras_CO: Clasificacion[] = []
    derechos_basicos_aprendizaje_CO: Clasificacion[] = []
    evidencia_aprendizajes_CO: Clasificacion[] = []
    cts = {}
    cursos: Clasificacion[]
    dificultades: Clasificacion[]
    dirigidos: Clasificacion[]
    docente: Clasificacion = undefined
    ejes: Clasificacion[] = []
    form: UntypedFormGroup
    genreOptions: Clasificacion[] = []
    habilidades: Clasificacion[] = []
    conocimientos: Clasificacion[] = []
    unidades_tematicas: Clasificacion[] = []
    tipo_textos: Clasificacion[] = []
    hpc: Clasificacion[] = []
    indicadores: Clasificacion[] = []
    islge: string = "LGE"
    mencion: Clasificacion[] = []
    mencionSelected: Clasificacion = undefined
    mezclados: Clasificacion[] = []
    nivel1: Clasificacion[] = []
    nivel2: Clasificacion[] = []
    nivel3: Clasificacion[] = []
    oahs: Clasificacion[] = []
    oas: Clasificacion[] = []
    pie: Clasificacion = undefined
    showhpc = false
    sub_ejes: Clasificacion[] = []
    sub_habilidades: Clasificacion[] = []
    sub_indicadores: Clasificacion[] = []
    sub_temas: Clasificacion[] = []
    suficiencia: Clasificacion[] = []
    suficienciaSelected: Clasificacion = undefined
    temas: Clasificacion[] = []
    typeText: Clasificacion[] = []
    unidades: Clasificacion[] = []
    @Input() clasificaciones_seleccionadas: Clasificacion[] = []
    @Input() material: boolean = false
    @Input() curriculum: string = "LGE"
    @Output() clasificacionesChange: EventEmitter<Clasificacion[]> = new EventEmitter<Clasificacion[]>()

    clasificacionesByTipo: { [clasificacionTipo: string]: Clasificacion }
    clasificacionesSeleccionadasByTipo: { [clasificacionTipo: string]: Clasificacion[] }

    updateOptions: any
    resetOptions: any
    showComponenteMx: boolean = false
    showBloqueMx: boolean = true
    showSearchClasificacionId: boolean = true
    showCurriculum: boolean = true
    showAsignatura: boolean = true
    showClasificacionId: boolean = true
    showTituloClasificacionTipo: boolean = true

    constructor(
        private asignaturasService: Asignaturas,
        private clasificacionTiposService: ClasificacionTipos,
        private clasificacionesService: Clasificaciones,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        if (this.perfil == "profesor") {
            this.showSearchClasificacionId = false
            this.showCurriculum = false
            this.showAsignatura = false
            this.showClasificacionId = false
            this.showTituloClasificacionTipo = false
        }

        this.setClasificacionTipos()
        this.setClasificacionesSeleccionadasByTipo()
    }

    async setClasificacionTiposNew() {
        let clasificacionTipos = (await this.clasificacionTiposService.where()) as ClasificacionTipo[]
        for (let clasificacionTipo of clasificacionTipos) {
            this.cts[clasificacionTipo.clasificacion_tipo] = clasificacionTipo.id
        }

        const clasificacionTipoIniciales = [
            "curso",
            "dificultad",
            "dirigido",
            "habilidades de pensamiento científico",
            "apto para plan personal"
        ]

        let clasificaciones = await this.clasificacionesService.where({
            clasificacion_tipo: {
                clasificacion_tipo: clasificacionTipoIniciales
            },
            raw: 1,
            render_options: {
                only: ["id", "clasificacion_tipo_id", "clasificacion"]
            }
        })

        this.clasificacionesService
            .where({ clasificacion: { clasificacion_tipo_id: this.cts["curso"] } })
            .then((clasificaciones: Clasificacion[]) => {
                this.cursos = clasificaciones
            })
    }
    // CODIGO NUEVO - END

    async setClasificacionTipos() {
        let clasificacionTipos = (await this.clasificacionTiposService.where()) as ClasificacionTipo[]
        for (let clasificacionTipo of clasificacionTipos) {
            this.cts[clasificacionTipo.clasificacion_tipo] = clasificacionTipo.id
        }

        this.clasificacionesService
            .where({ clasificacion: { clasificacion_tipo_id: this.cts["dificultad"] } })
            .then((clasificaciones: Clasificacion[]) => {
                this.dificultades = clasificaciones
            })

        this.clasificacionesService
            .where({ clasificacion: { clasificacion_tipo_id: this.cts["dirigido"] } })
            .then((clasificaciones: Clasificacion[]) => {
                this.dirigidos = clasificaciones
            })

        this.clasificacionesService
            .where({ clasificacion: { clasificacion_tipo_id: this.cts["habilidades de pensamiento científico"] } })
            .then((clasificaciones: Clasificacion[]) => {
                this.hpc = clasificaciones
            })

        this.clasificacionesService
            .where({ clasificacion: { clasificacion_tipo_id: this.cts["apto para plan personal"] } })
            .then((clasificaciones: Clasificacion[]) => {
                this.aptoParaPlanPersonal = clasificaciones
            })

        this.asignaturas = (await this.asignaturasService.where({ plataforma: config.plataforma.name })) as Asignatura[]

        let clasificaciones = (await this.clasificacionesService.where({
            clasificacion: { clasificacion: ["Programa de Integración Escolar", "Docente", "Alumno"] }
        })) as Clasificacion[]
        clasificaciones.forEach(c => {
            if (c["clasificacion"] == "Programa de Integración Escolar") {
                this.pie = c
            }
            if (c["clasificacion"] == "Docente") {
                this.docente = c
            }
            if (c["clasificacion"] == "Alumno") {
                this.alumno = c
            }
        })

        this.resetOptions = {
            asignatura: {
                controls: [
                    "eje_hab_unidad_id",
                    "oa_id",
                    "ae_id",
                    "indicador_id",
                    "eje_id",
                    "sub_eje_id",
                    "uni_id",
                    "hab_id",
                    "oah_id",
                    "componente_id",
                    "competencia_id",
                    "tema_id",
                    "sub_tema_id",
                    "type_text_id",
                    "genre_id",
                    "cmo_id",
                    "bloque_mx_id",
                    "tema_mx_id",
                    "subtema_mx_id",
                    "unidad_tematica_id",
                    "habilidad_id",
                    "conocimiento_id",
                    "tipo_texto_id"
                ],
                fields: [
                    "oas",
                    "aes",
                    "oahs",
                    "indicadores",
                    "sub_ejes",
                    "temas",
                    "sub_temas",
                    "temas_mx",
                    "subtemas_mx",
                    "ejes",
                    "unidades_tematicas",
                    "habilidades",
                    "conocimientos"
                ]
            },
            curso: {
                controls: [
                    "eje_hab_unidad_id",
                    "oa_id",
                    "ae_id",
                    "oah_id",
                    "indicador_id",
                    "componente_id",
                    "competencia_id",
                    "bloque_mx_id",
                    "tema_mx_id",
                    "subtema_mx_id"
                ],
                fields: ["oas", "aes", "oahs", "indicadores", "temas_mx", "subtemas_mx"]
            },
            mezclados: {
                controls: ["oa_id", "ae_id", "indicador_id"],
                fields: ["indicadores"]
            },
            unidad: {
                controls: ["oa_id", "ae_id", "oah_id", "indicador_id"],
                fields: ["indicadores"]
            },
            habilidad: {
                controls: ["ae_id", "oah_id"],
                fields: ["oahs"]
            },
            oa: {
                controls: ["indicador_id"],
                fields: []
            },
            eje: {
                controls: [
                    "sub_eje_id",
                    "oa_id",
                    "ae_id",
                    "indicador_id",
                    "tema_id",
                    "sub_tema_id",
                    "unidad_tematica_id"
                ],
                fields: ["indicadores", "unidad_tematicas", "temas"]
            },
            tema: {
                controls: ["sub_tema_id"],
                fields: []
            },
            componente: {
                controls: ["afirmacion_id"],
                fields: ["afirmaciones"]
            },
            afirmacion: {
                controls: ["evidencia_id"],
                fields: ["evidencias"]
            }
        }

        this.updateOptions = {
            SABER: {
                asignaturas: "Puntaje Nacional Colombia",
                clasificaciones: {
                    cursos: this.cts["nivel colombia"],
                    dirigidos: this.cts["dirigido"]
                },
                form: [
                    "asignatura_id",
                    "curso_id",
                    "componente_id",
                    "hpc_id",
                    "competencia_id",
                    "dificultad_id",
                    "dirigido_id",
                    "afirmacion_id",
                    "evidencia_id"
                ],
                formArray: [{ form: "apto_para_plan_personal_id", array: this.aptoParaPlanPersonal }],
                onUpdate: {
                    asignatura: {
                        componentes: this.cts["componente"],
                        competencias: this.cts["competencia"]
                    },
                    curso: {
                        componentes: this.cts["componente"],
                        competencias: this.cts["competencia"]
                    },
                    componente: {
                        afirmaciones: this.cts["Afirmación_CO"]
                    },
                    afirmacion: {
                        evidencias: this.cts["Evidencia_CO"]
                    }
                }
            },
            LGE: {
                asignaturas: "Aprendolibre",
                clasificaciones: {
                    cursos: this.cts["curso"],
                    dirigidos: this.cts["dirigido"]
                },
                form: [
                    "asignatura_id",
                    "curso_id",
                    "eje_id",
                    "eje_hab_unidad_id",
                    "uni_id",
                    "hpc_id",
                    "hab_id",
                    "oa_id",
                    "oah_id",
                    "indicador_id",
                    "pie_id",
                    "docente_id",
                    "dificultad_id",
                    "dirigido_id",
                    "type_text_id",
                    "genre_id"
                ],
                formArray: [{ form: "apto_para_plan_personal_id", array: this.aptoParaPlanPersonal }],
                onUpdate: {
                    asignatura: {
                        habilidades: this.cts["habilidad cognitiva LGE"],
                        unidades: this.cts["unidad LGE"],
                        ejes: this.cts["eje temático LGE"],
                        typeText: this.cts["tipo de texto"]
                    },
                    curso: {
                        habilidades: this.cts["habilidad cognitiva LGE"],
                        unidades: this.cts["unidad LGE"],
                        ejes: this.cts["eje temático LGE"]
                    },
                    eje: {
                        oas: this.cts["objetivo de aprendizaje"]
                    },
                    habilidad: {
                        oahs: this.cts["objetivo de aprendizaje"]
                    },
                    oa: {
                        indicadores: this.cts["indicador LGE"]
                    },
                    ae: {
                        indicadores: this.cts["indicador LOCE"]
                    },
                    type_text: {
                        genreOptions: this.cts["genero"]
                    }
                }
            },
            LOCE: {
                asignaturas: "Puntaje Nacional",
                clasificaciones: {
                    cursos: this.cts["curso"],
                    dirigidos: this.cts["dirigido"]
                },
                form: [
                    "asignatura_id",
                    "curso_id",
                    "eje_hab_unidad_id",
                    "hpc_id",
                    "ae_id",
                    "indicador_id",
                    "pie_id",
                    "docente_id",
                    "dificultad_id",
                    "dirigido_id"
                ],
                formArray: [{ form: "apto_para_plan_personal_id", array: this.aptoParaPlanPersonal }],
                onUpdate: {
                    asignatura: {
                        mezclados: [this.cts["habilidad cognitiva LOCE"], this.cts["unidad LOCE"]]
                    },
                    curso: {
                        mezclados: [this.cts["habilidad cognitiva LOCE"], this.cts["unidad LOCE"]]
                    },
                    mezclados: {
                        aes: this.cts["aprendizaje esperado"]
                    }
                }
            },
            GRADUATE: {
                asignaturas: "Gradúate",
                clasificaciones: {
                    cursos: this.cts["curso gradúate"],
                    dirigidos: this.cts["dirigido"]
                },
                form: ["asignatura_id", "curso_id", "eje_id", "sub_eje_id", "hpc_id", "dificultad_id", "dirigido_id"],
                formArray: [{ form: "apto_para_plan_personal_id", array: this.aptoParaPlanPersonal }],
                onUpdate: {
                    asignatura: {
                        ejes: this.cts["eje temático gradúate"]
                    },
                    curso: {
                        ejes: this.cts["eje temático gradúate"]
                    },
                    eje: {
                        sub_ejes: this.cts["sub-eje temático gradúate"]
                    }
                }
            },
            PSU: {
                asignaturas: "Puntaje Nacional",
                clasificaciones: {
                    cursos: this.cts["curso PSU"],
                    suficiencia: this.cts["suficiencia"],
                    mencion: this.cts["mención"],
                    dirigidos: this.cts["dirigido"]
                },
                form: [
                    "asignatura_id",
                    "curso_id",
                    "eje_id",
                    "sub_eje_id",
                    "hab_id",
                    "hpc_id",
                    "mencion",
                    "suficiencia",
                    "dificultad_id",
                    "dirigido_id",
                    "type_text_id",
                    "genre_id",
                    "cmo_id"
                ],
                formArray: [{ form: "apto_para_plan_personal_id", array: this.aptoParaPlanPersonal }],
                onUpdate: {
                    asignatura: {
                        ejes: this.cts["eje temático"],
                        habilidades: this.cts["habilidad cognitiva"],
                        typeText: this.cts["tipo de texto"],
                        cmoOptions: this.cts["contenido mínimo obligatorio CMO"]
                    },
                    eje: {
                        sub_ejes: this.cts["sub eje tematico"]
                    },
                    type_text: {
                        genreOptions: this.cts["genero"]
                    }
                }
            },
            PSUM: {
                asignaturas: "Moraleja",
                clasificaciones: {
                    cursos: this.cts["curso"],
                    suficiencia: this.cts["suficiencia"],
                    mencion: this.cts["mención"],
                    dirigidos: this.cts["dirigido"]
                },
                form: [
                    "asignatura_id",
                    "curso_id",
                    "eje_id",
                    "tema_id",
                    "sub_tema_id",
                    "hab_id",
                    "hpc_id",
                    "mencion",
                    "suficiencia",
                    "dificultad_id",
                    "dirigido_id"
                ],
                formArray: [{ form: "apto_para_plan_personal_id", array: this.aptoParaPlanPersonal }],
                onUpdate: {
                    asignatura: {
                        ejes: this.cts["eje temático moraleja"],
                        habilidades: this.cts["habilidad cognitiva"]
                    },
                    eje: {
                        temas: this.cts["tema moraleja"]
                    },
                    tema: {
                        sub_temas: this.cts["sub tema moraleja"]
                    }
                }
            },
            PAA: {
                asignaturas: "College Board",
                clasificaciones: {
                    cursos: this.cts["curso"],
                    dirigidos: this.cts["dirigido"]
                },
                form: [
                    "asignatura_id",
                    "eje_id",
                    "sub_eje_id",
                    "hpc_id",
                    "hab_id",
                    "indicador_id",
                    "sub_indicador_id",
                    "dificultad_id",
                    "dirigido_id"
                ],
                formArray: [{ form: "apto_para_plan_personal_id", array: this.aptoParaPlanPersonal }],
                onUpdate: {
                    asignatura: {
                        ejes: this.cts["eje temático paa"],
                        habilidades: this.cts["nivel cognitivo"]
                    },
                    eje: {
                        sub_ejes: this.cts["sub eje temático paa"]
                    },
                    sub_eje: {
                        indicadores: this.cts["indicador paa"]
                    },
                    indicador: {
                        sub_indicadores: this.cts["sub indicador paa"]
                    }
                }
            },
            PDT: {
                asignaturas: "Puntaje Nacional",
                clasificaciones: {
                    cursos: this.cts["curso"],
                    suficiencia: this.cts["suficiencia"],
                    mencion: this.cts["mención"]
                },
                form: [
                    "asignatura_id",
                    "curso_id",
                    "nivel1_id",
                    "nivel2_id",
                    "nivel3_id",
                    "mencion",
                    "suficiencia",
                    "dificultad_id",
                    "hab_id",
                    "sub_hab_id"
                ],
                formArray: [{ form: "apto_para_plan_personal_id", array: this.aptoParaPlanPersonal }],
                onUpdate: {
                    asignatura: {
                        nivel1: this.cts["nivel 1 prueba de transición"],
                        habilidades: this.cts["habilidad prueba de transición"]
                    },
                    nivel1: {
                        nivel2: this.cts["nivel 2 prueba de transición"]
                    },
                    nivel2: {
                        nivel3: this.cts["nivel 3 prueba de transición"]
                    },
                    habilidad: {
                        sub_habilidades: this.cts["subhabilidad prueba de transición"]
                    }
                }
            },
            PAES: {
                asignaturas: "Puntaje Nacional",
                clasificaciones: {
                    cursos: this.cts["curso"]
                },
                form: [
                    "asignatura_id",
                    "curso_id",
                    "eje_id",
                    "unidad_tematica_id",
                    "tema_id",
                    "conocimiento_id",
                    "hab_id",
                    "tipo_texto_id",
                    "dificultad_id"
                ],
                formArray: [{ form: "apto_para_plan_personal_id", array: this.aptoParaPlanPersonal }],
                onUpdate: {
                    asignatura: {
                        ejes: this.cts["Eje PAES"],
                        habilidades: this.cts["Habilidad PAES"],
                        conocimientos: this.cts["Conocimiento PAES"],
                        tipo_textos: this.cts["Tipo de texto PAES"]
                    },
                    eje: {
                        unidades_tematicas: this.cts["Unidad Temática PAES"]
                    },
                    unidad_tematica: {
                        temas: this.cts["Tema PAES"]
                    }
                }
            },
            CurricularMexico: {
                asignaturas: "Aprendolibre Mexico",
                clasificaciones: {
                    cursos: this.cts["Curso MX"],
                    suficiencia: this.cts["suficiencia"]
                },
                form: [
                    "asignatura_id",
                    "curso_id",
                    "bloque_mx_id",
                    // "componente_mx_id",
                    "tema_mx_id",
                    "subtema_mx_id",
                    // "contenidocentral_mx_id",
                    "dificultad_id"
                ],
                formArray: [{ form: "apto_para_plan_personal_id", array: this.aptoParaPlanPersonal }],
                onUpdate: {
                    asignatura: {
                        bloques_mx: this.cts["Bloque_mx"]
                        // componentes_mx: this.cts["componente_mx"]
                    },
                    curso: {
                        bloques_mx: this.cts["Bloque_mx"]
                    },
                    bloques_mx: {
                        temas_mx: this.cts["Temas_mx"]
                    },
                    temas_mx: {
                        subtemas_mx: this.cts["Subtemas_mx"]
                    }
                    // componentes_mx: {
                    //     contenidocentrales_mx: this.cts["contenidocentral_mx"]
                    // }
                }
            },
            CurricularColombiaDBA: {
                asignaturas: "Aprendolibre Colombia",
                clasificaciones: {
                    cursos: this.cts["curso colombia"],
                    suficiencia: this.cts["suficiencia"]
                },
                form: [
                    "asignatura_id",
                    "curso_id",
                    "eje_id",
                    "dificultad_id",
                    "categoria_organizadora_CO_id",
                    "derechos_basicos_aprendizaje_CO_id",
                    "evidencia_aprendizaje_CO_id"
                ],
                formArray: [{ form: "apto_para_plan_personal_id", array: this.aptoParaPlanPersonal }],
                onUpdate: {
                    asignatura: {
                        ejes: this.cts["eje tematico colombia"]
                    },
                    curso: {
                        categoria_organizadoras_CO: this.cts["Categoría organizadora_CO"]
                    },
                    eje: {
                        derechos_basicos_aprendizaje_CO: this.cts["Derechos básicos de aprendizaje_CO"]
                    },
                    derechos_basicos_aprendizaje_CO: {
                        evidencia_aprendizajes_CO: this.cts["Evidencias de aprendizaje_CO"]
                    }
                }
            },
            CurricularColombiaEBC: {
                asignaturas: "Aprendolibre Colombia",
                clasificaciones: {
                    cursos: this.cts["Curso EBC_CO"],
                    suficiencia: this.cts["suficiencia"]
                },
                form: ["asignatura_id", "curso_id", "eje_id", "sub_eje_id", "oa_id", "indicador_id"],
                formArray: [{ form: "apto_para_plan_personal_id", array: this.aptoParaPlanPersonal }],
                onUpdate: {
                    asignatura: {
                        ejes: this.cts["Eje temático EBC_CO"]
                    },
                    eje: {
                        sub_ejes: this.cts["Subeje temático EBC_CO"]
                    },
                    sub_eje: {
                        oa: this.cts["OA EBC_CO"]
                    },
                    oa: {
                        incicador: this.cts["Indicadores EBC_CO"]
                    }
                }
            }
        }

        this.updateCurriculum(this.curriculum)
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes &&
            changes["curriculum"] &&
            !changes["curriculum"].isFirstChange() &&
            changes["curriculum"].currentValue !== null &&
            changes["curriculum"].currentValue !== undefined
        ) {
            this.updateCurriculum(this.curriculum)
        }

        if (
            changes &&
            changes["asignaturaId"] &&
            !changes["asignaturaId"].isFirstChange &&
            changes["asignaturaId"].currentValue !== null &&
            changes["asignaturaId"].currentValue !== undefined
        ) {
            this.form.controls["asignatura_id"].setValue(
                this.asignaturas.find(asignatura => asignatura.id == this.asignaturaId)
            )
        }

        if (
            changes &&
            changes["clasificaciones_seleccionadas"] &&
            !changes["clasificaciones_seleccionadas"].isFirstChange()
        ) {
            this.setClasificacionesSeleccionadasByTipo()
        }
    }

    updateCurriculum(newValue) {
        if (newValue) this.makeForm(newValue)

        let options = this.updateOptions[this.curriculum]
        this.islge = this.curriculum

        if (options) {
            //TODO: HAY QUE BUSCAR LAS ASIGNATURAS SEGUN TIPO INSTRUMENTO, CURRICULUM Y PLATAFORMA, EJEMPLO: si se elige lge de puntaje, deberia elergise las asignaturas curriculum de puntaje
            this.asignaturasService.where({ plataforma: config.plataforma.name }).then((asignaturas: Asignatura[]) => {
                this.asignaturas = asignaturas

                this.setAsignaturaIfPresent()
            })

            if (options.clasificaciones) {
                Object.keys(options.clasificaciones).forEach(nombreVar => {
                    this.clasificacionesService
                        .where({ clasificacion: { clasificacion_tipo_id: options.clasificaciones[nombreVar] } })
                        .then((clasificaciones: Clasificacion[]) => {
                            this[nombreVar] = clasificaciones
                        })
                })
            }
        }
    }

    updateClasificacion(controlName, tipo) {
        this.showhpc =
            this.form.controls.hpc_id &&
            this.form.controls.asignatura_id.value &&
            ["Ciencias PSU", "Ciencias Biología", "Ciencias Física", "Ciencias Química"].includes(
                this.form.controls.asignatura_id.value.asignatura
            )

        let value = this.form.controls[controlName].value.id

        let options = this.updateOptions[this.curriculum]

        // if (options.asignaturas == "Aprendolibre Mexico" && tipo == "asignatura") {
        // this.showBloqueMx = true
        // this.showComponenteMx = this.form.controls[controlName].value.clasificacion.includes("achillerato")
        // }

        if (options && options.onUpdate[tipo]) {
            Object.keys(options.onUpdate[tipo]).forEach(nameVar => {
                let params: any = {
                    clasificacion: {
                        clasificacion_tipo_id: options.onUpdate[tipo][nameVar]
                    },
                    clasificacion_clasificaciones: {
                        clasificacion_id: value
                    }
                }

                if (tipo == "curso") {
                    let asignatura = this.form.controls["asignatura_id"]

                    if (asignatura && asignatura.value) {
                        params.clasificacion.asignatura_id = asignatura.value.id
                    }
                }

                if (tipo == "asignatura") {
                    if (nameVar == "habilidades" && this.curriculum == "PSU" && value > 4 && value < 8)
                        params.clasificacion.asignatura_id = 4
                    else params.clasificacion.asignatura_id = value
                    delete params.clasificacion_clasificaciones
                    let curso = this.form.controls["curso_id"]
                    if (curso && curso.value && this.curriculum != "PSU" && this.curriculum != "PSUM") {
                        let cursoId = curso.value.id

                        params.clasificacion_clasificaciones = { clasificacion_id: cursoId }
                    }
                }

                this.clasificacionesService.where(params).then((clasificaciones: Clasificacion[]) => {
                    this[nameVar] = clasificaciones
                })
            })
        }

        if (this.resetOptions[tipo]) {
            this.resetOptions[tipo].controls.forEach(field => {
                if (this.form.controls[field]) this.form.controls[field].setValue(0)
            })

            this.resetOptions[tipo].fields.forEach(field => {
                this[field] = []
            })
        }
    }

    makeForm(plataforma_name: string) {
        let obj = this.updateOptions[plataforma_name].form.reduce((acc, field) => {
            acc[field] = new UntypedFormControl(0)

            return acc
        }, {})

        let obj2 = this.updateOptions[plataforma_name].formArray.reduce((acc, field) => {
            acc[field.form] = new UntypedFormArray([])
            this.addFormArrayControls(acc[field.form], field.array)
            return acc
        }, {})

        this.form = new UntypedFormGroup({ ...obj, ...obj2 })

        this.setAsignaturaIfPresent()
    }

    private setAsignaturaIfPresent() {
        if (this.asignaturaId) {
            this.form.controls["asignatura_id"].setValue(
                this.asignaturas.find(asignatura => asignatura.id == this.asignaturaId)
            )
            this.updateClasificacion("asignatura_id", "asignatura")
        }
    }

    private addFormArrayControls(formarray: UntypedFormArray, array: any[]) {
        array.forEach((o, i) => {
            const control = new UntypedFormControl()
            formarray.push(control)
        })
    }

    agregarId() {
        let alreadyIn = this.clasificaciones_seleccionadas.find(c => c.id == this.clasificacionId)
        if (alreadyIn) {
            alert("La clasificación escogida ya existe!")
            return
        }

        this.clasificacionesService
            .find(this.clasificacionId)
            .then(c => {
                this.addClasificacion(c)
                this.clasificacionesChange.emit(this.clasificaciones_seleccionadas)
            })
            .catch(e => {
                alert("No se encontró clasificación con ID requerido!")
            })
    }

    private addClasificaciones(clasificaciones: Clasificacion[]) {
        this.clasificaciones_seleccionadas = this.clasificaciones_seleccionadas.concat(clasificaciones)

        this.setClasificacionesSeleccionadasByTipo()
    }

    private addClasificacion(clasificacion: Clasificacion) {
        this.addClasificaciones([clasificacion])
    }

    private removeClasificacion(index: number) {
        this.clasificaciones_seleccionadas.splice(index, 1)

        this.setClasificacionesSeleccionadasByTipo()
    }

    private setClasificacionesSeleccionadasByTipo() {
        this.clasificacionesSeleccionadasByTipo = this.clasificaciones_seleccionadas.reduce((acc, c) => {
            acc[c.clasificacion_tipo.clasificacion_tipo] = acc[c.clasificacion_tipo.clasificacion_tipo] || []
            acc[c.clasificacion_tipo.clasificacion_tipo].push(c)

            return acc
        }, {})
    }

    agregar() {
        let options = this.updateOptions[this.curriculum]

        let fields = options.form.concat(options.formArray.map(fa => fa.form))

        fields.forEach(controlName => {
            if (controlName == "pie_id") {
                if (this.form.controls["pie_id"].value) {
                    this.addClasificacion(this.pie)
                }
            } else if (controlName == "docente_id") {
                if (this.form.controls["docente_id"].value) {
                    this.addClasificacion(this.docente)
                } else if (this.material) {
                    this.addClasificacion(this.alumno)
                }
            } else if (controlName == "apto_para_plan_personal_id") {
                let clasificacionesFiltered = this.form.controls[controlName].value
                    .map((v, i) => (v ? this.aptoParaPlanPersonal[i] : null))
                    .filter(v => v !== null)
                this.addClasificaciones(clasificacionesFiltered)
            } else if (controlName != "asignatura_id") {
                let clasificacion = this.form.controls[controlName].value
                if (clasificacion && !this.clasificaciones_seleccionadas.find(cs => cs.id == clasificacion.id)) {
                    this.addClasificacion(clasificacion)
                }
            }
        })

        this.clasificacionesChange.emit(this.clasificaciones_seleccionadas)
    }

    agregarMencion() {
        if (this.mencionSelected) {
            let popPrevious = this.clasificaciones_seleccionadas.find(
                c => c.clasificacion_tipo_id == this.mencionSelected.clasificacion_tipo_id
            )
            if (popPrevious) this.quitar(popPrevious.id)
            this.addClasificacion(this.mencionSelected)
        }

        this.clasificacionesChange.emit(this.clasificaciones_seleccionadas)
    }

    agregarSuficiencia() {
        if (this.suficienciaSelected) {
            let popPrevious = this.clasificaciones_seleccionadas.find(
                c => c.clasificacion_tipo_id == this.suficienciaSelected.clasificacion_tipo_id
            )
            if (popPrevious) this.quitar(popPrevious.id)
            this.addClasificacion(this.suficienciaSelected)
        }

        this.clasificacionesChange.emit(this.clasificaciones_seleccionadas)
    }

    quitar(id: number) {
        for (var i = 0; i < this.clasificaciones_seleccionadas.length; i++) {
            if (this.clasificaciones_seleccionadas[i].id == id) this.removeClasificacion(i)
        }
        this.clasificacionesChange.emit(this.clasificaciones_seleccionadas)
    }
}
