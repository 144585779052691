<div class="caja-envio">
    <h5>
        {{
            "evaluar.generar_instrumento.opciones.compartir"
                | t: { evaluacionTipo: (tipoEvaluacion | transformInstrumento: "singular" | capitalize) }
        }}
    </h5>
    <cui-button-link
        [isBlock]="true"
        buttonType="confirm"
        id="compartir_link"
        class="btn2 btn_style_3"
        (click)="popUpPilotaje(); imprimirAlCompartir = false"
    >
        {{
            "evaluar.generar_instrumento.opciones.enviar"
                | t: { evaluacionTipo: (tipoEvaluacion | transformInstrumento: "singular" | capitalize) }
        }}
        Online
    </cui-button-link>
    <cui-button-link
        [isBlock]="true"
        buttonType="confirm"
        *ngIf="!evaluacionId && showAsignarImpreso"
        class="btn2 btn_style_3"
        (click)="popUpPilotaje(); imprimirAlCompartir = true"
    >
        {{
            "evaluar.generar_instrumento.opciones.asignar"
                | t: { evaluacionTipo: (tipoEvaluacion | transformInstrumento: "singular" | capitalize) }
        }}
    </cui-button-link>
    <div class="tooltip-impresion-container">
        <a href="javascript:void(0)" [tooltip]="'evaluar.generar_instrumento.opciones.impresion.tooltip' | t">
            <fa name="question-circle-o"></fa>
            {{ "evaluar.generar_instrumento.opciones.impresion.tooltip_label" | t }}
        </a>
    </div>
    <cui-button-link
        [isBlock]="true"
        buttonType="confirm"
        *ngIf="!evaluacionId && showButtonGuardar"
        class="btn2 btn_style_3 separador"
        [routerLinkValue]="urlHistorial"
        [queryParamsValue]="historialQueryParams"
    >
        {{ "evaluar.generar_instrumento.opciones.button_undo" | t }}
    </cui-button-link>

    <ng-container *ngIf="!hideImprimirEvaluacion">
        <ng-container *ngIf="(evaluacionId || ultimaEvaluacionId) && instrumento">
            <h5>{{ "evaluar.generar_instrumento.opciones.impresion.titulo" | t }}</h5>

            <cui-button-link
                [isBlock]="true"
                *ngIf="instrumento.propio && instrumento.instrumento_material"
                class="btn_style"
                (click)="downloadInstrumentoPropio()"
                tooltip="{{ 'evaluar.generar_instrumento.opciones.download_instrumento_material.tooltip' | t }}"
            >
                <span class="glyphicon glyphicon-print"></span>
                {{ "evaluar.generar_instrumento.opciones.download_instrumento_material.title" | t }}
            </cui-button-link>

            <cui-button-link [isBlock]="true" id="print_link" class="btn_style" (click)="imprimir('alumno')">
                <span class="glyphicon glyphicon-print"></span>
                {{ "evaluar.generar_instrumento.opciones.impresion.alumnos" | t }}
            </cui-button-link>

            <cui-button-link
                [isBlock]="true"
                id="print_link"
                class="btn_style"
                *ngIf="evaluacionFormas?.length > 0"
                (click)="imprimirFormas('alumno')"
            >
                <span class="glyphicon glyphicon-print"></span>
                {{ "evaluar.generar_instrumento.opciones.impresion.formas" | t }}
            </cui-button-link>
            <p class="details">
                {{ "evaluar.generar_instrumento.opciones.impresion.formas_detail" | t }}
            </p>
            <div class="imprimir-practicas-link" (click)="openInstructivoImprimirEvent.emit(true)">
                <a>{{ "evaluar.generar_instrumento.opciones.impresion.instructivo_hoja_respuestas" | t }}</a>
            </div>

            <cui-button-link [isBlock]="true" id="print_link_prof" class="btn_style" (click)="imprimir('profesor')">
                <span class="glyphicon glyphicon-print"></span>
                {{ "evaluar.generar_instrumento.opciones.impresion.profes" | t }}
            </cui-button-link>

            <cui-button-link
                [isBlock]="true"
                id="print_link_prof"
                class="btn_style"
                *ngIf="evaluacionFormas?.length > 0"
                (click)="imprimirFormas('profesor')"
            >
                <span class="glyphicon glyphicon-print"></span>
                {{ "evaluar.generar_instrumento.opciones.impresion.profes_formas" | t }}
            </cui-button-link>
            <p class="details">
                {{ "evaluar.generar_instrumento.opciones.impresion.profes_formas_detail" | t }}
            </p>
            <div class="imprimir-practicas-link" (click)="openInstructivoImprimirEvent.emit(true)">
                <a>{{ "evaluar.generar_instrumento.opciones.impresion.instructivo_hoja_respuestas" | t }}</a>
            </div>
            <cui-button-link [isBlock]="true" id="print_link_prof" class="btn_style" (click)="imprimirHojas()">
                <span class="glyphicon glyphicon-print"></span>
                {{ "evaluar.generar_instrumento.opciones.impresion.hoja" | t }}
            </cui-button-link>

            <cui-button-link
                [isBlock]="true"
                id="print_link_prof"
                class="btn_style"
                *ngIf="evaluacionFormas?.length > 0"
                (click)="imprimirHojas(true)"
            >
                <span class="glyphicon glyphicon-print"></span>
                {{ "evaluar.generar_instrumento.opciones.impresion.hoja_formas" | t }}
            </cui-button-link>
            <p class="details">
                {{ "evaluar.generar_instrumento.opciones.impresion.hoja_formas_detail" | t }}
            </p>
            <div class="imprimir-practicas-link" (click)="openInstructivoImprimirEvent.emit(true)">
                <a>{{ "evaluar.generar_instrumento.opciones.impresion.instructivo_hoja_respuestas" | t }}</a>
            </div>
            <div class="warning separador">
                {{ "evaluar.generar_instrumento.opciones.impresion.warning" | t }}
            </div>

            <cui-button-link
                [isBlock]="true"
                id="print_link_hoja_vacia"
                class="btn_style"
                [href]="urlHojaRespuesta"
                targetValue="_blank"
                [isDownload]="true"
            >
                <span class="glyphicon glyphicon-print"></span>
                <span
                    [innerHTML]="'evaluar.generar_instrumento.opciones.impresion.hoja_vacia' | t | trustedhtml"
                ></span>
            </cui-button-link>
            <p class="details">
                {{ "evaluar.generar_instrumento.opciones.impresion.hoja_vacia_detail" | t }}
            </p>

            <div *ngIf="showLinkAlumnoAlCompartir && !isPresencial">
                {{ "evaluar.generar_instrumento.opciones.impresion.alumnos_compartir1" | t }}
                {{ "realizarlo" | genderize: tipoEvaluacion }}
                {{ "evaluar.generar_instrumento.opciones.impresion.alumnos_compartir2" | t }}:
                <br />
                <div class="copyLinkContainer">
                    <span #enlaceEstudiante>
                        {{ environment.domain.alumnos }}/evaluaciones/{{ evaluacionId || ultimaEvaluacionId }}/realizar
                    </span>
                    <cui-button class="btn-copy btn_style" (click)="copyToClipboard(enlaceEstudiante)">
                        {{ "evaluar.generar_instrumento.opciones.impresion.alumnos_compartir_copiar" | t }}&nbsp;
                        <fa name="files-o"></fa>
                    </cui-button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="(evaluacionId || ultimaEvaluacionId) && instrumentos">
            <h5>{{ "evaluar.generar_instrumento.opciones.impresion.titulo" | t }}</h5>
            <cui-button-link [isBlock]="true" id="print_link" class="btn_style" (click)="imprimirTodos('alumno')">
                <span class="glyphicon glyphicon-print"></span>
                {{ "evaluar.generar_instrumento.opciones.impresion.alumnos" | t }}
            </cui-button-link>
            <p class="details">
                {{ "evaluar.generar_instrumento.opciones.impresion.formas_detail" | t }}
            </p>
            <div class="imprimir-practicas-link" (click)="openInstructivoImprimirEvent.emit(true)">
                <a>{{ "evaluar.generar_instrumento.opciones.impresion.instructivo_hoja_respuestas" | t }}</a>
            </div>
            <cui-button-link
                [isBlock]="true"
                id="print_link_prof"
                class="btn_style"
                (click)="imprimirTodos('profesor')"
            >
                <span class="glyphicon glyphicon-print"></span>
                {{ "evaluar.generar_instrumento.opciones.impresion.profes" | t }}
            </cui-button-link>
            <p class="details">
                {{ "evaluar.generar_instrumento.opciones.impresion.profes_formas_detail" | t }}
            </p>
            <div class="imprimir-practicas-link" (click)="openInstructivoImprimirEvent.emit(true)">
                <a>{{ "evaluar.generar_instrumento.opciones.impresion.instructivo_hoja_respuestas" | t }}</a>
            </div>
            <cui-button-link [isBlock]="true" id="print_link_prof" class="btn_style" (click)="imprimirHojasTodos()">
                <span class="glyphicon glyphicon-print"></span>
                {{ "evaluar.generar_instrumento.opciones.impresion.hoja" | t }}
            </cui-button-link>
            <p class="details">
                {{ "evaluar.generar_instrumento.opciones.impresion.hoja_formas_detail" | t }}
            </p>
            <div class="imprimir-practicas-link" (click)="openInstructivoImprimirEvent.emit(true)">
                <a>{{ "evaluar.generar_instrumento.opciones.impresion.instructivo_hoja_respuestas" | t }}</a>
            </div>
            <div class="warning separador">
                {{ "evaluar.generar_instrumento.opciones.impresion.warning" | t }}
            </div>

            <cui-button-link
                [isBlock]="true"
                id="print_link_hoja_vacia"
                class="btn_style"
                [href]="urlHojaRespuesta"
                targetValue="_blank"
                [isDownload]="true"
            >
                <span class="glyphicon glyphicon-print"></span>
                <span
                    [innerHTML]="'evaluar.generar_instrumento.opciones.impresion.hoja_vacia' | t | trustedhtml"
                ></span>
            </cui-button-link>
            <p class="details">
                {{ "evaluar.generar_instrumento.opciones.impresion.hoja_vacia_detail" | t }}
            </p>
        </ng-container>
    </ng-container>
    <!--<div class="opciones">
		<h5>Más opciones</h5>
		<cui-button-link [isBlock]="true" class=" btn2 btn_style ">Ver Resultados</cui-button-link>
		<cui-button-link [isBlock]="true" class=" btn2 btn_style ">Eliminar</cui-button-link>
	</div>-->
    <div *ngIf="isAdmin" class="opciones">
        <h5>{{ "evaluar.generar_instrumento.opciones.mas" | t }}</h5>
        <cui-button-link [isBlock]="true" class="btn2 btn_style" (click)="descargarTablaEspecificaciones()">
            {{ "evaluar.generar_instrumento.opciones.tabla_espec" | t }}
        </cui-button-link>
    </div>
</div>
<compartir-instrumento-profesor
    *ngIf="instrumento"
    [compartirTodosCursos]="compartirTodosCursos"
    [instrumento]="instrumento"
    (evaluacionCompartida)="onEvaluacionCompartida($event)"
    [openModal]="openModal"
    [closeOnFinished]="imprimirAlCompartir"
    [presencial]="imprimirAlCompartir"
    [tipoEvaluacion]="tipoEvaluacion"
    [redirect]="redirect"
    [enableAdminOptions]="enableAdminOptions"
></compartir-instrumento-profesor>
<compartir-instrumento-profesor
    *ngIf="instrumentos"
    [instrumentos]="instrumentos"
    (evaluacionesCompartidas)="onEvaluacionesCompartidas($event)"
    [openModal]="openModal"
    [closeOnFinished]="imprimirAlCompartir"
    [presencial]="imprimirAlCompartir"
    [tipoEvaluacion]="tipoEvaluacion"
    [redirect]="redirect"
    [enableAdminOptions]="enableAdminOptions"
></compartir-instrumento-profesor>

<div
    class="modal fade staticModal"
    bsModal
    #mandatoryLoading="bs-modal"
    [config]="{ ignoreBackdropClick: true, keyboard: false }"
    tabindex="0"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <ng-container *ngIf="!errorMsg">
                    <loading-layout #loadingLayout [noMarginY]="true" class="loading"></loading-layout>
                    <p class="loading-message">
                        {{ "evaluar.generar_instrumento.opciones.loading_message1" | t }}
                        <br />
                        {{ "evaluar.generar_instrumento.opciones.loading_message2" | t }}
                    </p>
                </ng-container>
                <ng-container *ngIf="errorMsg">
                    <p class="warning-icon">
                        <fa name="warning"></fa>
                    </p>
                    <p class="error-message">
                        {{ "evaluar.generar_instrumento.opciones.error1" | t }}
                        <br />
                        {{ "evaluar.generar_instrumento.opciones.error2" | t }}
                    </p>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    (click)="cancelPrint()"
                    *ngIf="!errorMsg"
                >
                    {{ "evaluar.generar_instrumento.opciones.cancel_button" | t }}
                </button>
                <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    *ngIf="errorMsg"
                    (click)="mandatoryLoading.hide()"
                >
                    {{ "evaluar.generar_instrumento.opciones.cerrar_button" | t }}
                </button>
            </div>
        </div>
    </div>
    <modal-instructivo-subir-respuestas
        [openInstructivoImprimirEvent]="openInstructivoImprimirEvent"
    ></modal-instructivo-subir-respuestas>
</div>
